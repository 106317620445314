<template>
  <div class="app-container">
    <eHeader
      ref="eheader"
      :query="query"
      :sites="sites"
      @has-partnerid="hasPartneridEmit" />
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column label="社区名称" prop="siteName" />
      <el-table-column prop="platformName" label="平台名称" width="120">
        <template slot-scope="scope">
          <span>{{getPlatformName(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="userId" label="客户ID" width="80"/>
      <el-table-column prop="ibId" label="合作伙伴ID"/>
      <el-table-column prop="userName" label="客户昵称">
        <template slot-scope="scope">
          <span>{{scope.row.userName}} #{{scope.row.loginOrder}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="accType" label="社区角色">
        <template slot-scope="scope">
          <span>{{ scope.row.accType==1? '交易员' :'跟随者'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="login" label="mt4账号"/>
      <el-table-column label="是否下架">
        <template slot-scope="scope">
          <span>{{ getStatus(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="phone" width="120" label="客户手机/邮箱">
        <template slot-scope="scope">
          <div>{{scope.row.phone || '-'}}</div>
          <div>{{scope.row.email || '-'}}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.status==10">开户中</span>
<!--          <span v-if="scope.row.status==11">开户成功</span>-->
          <span v-if="scope.row.status==12">开户失败</span>
          <span v-if="scope.row.status==20">有效</span>
          <span v-if="scope.row.status==21">绑定失败</span>
          <span v-if="scope.row.status==22">失效</span>
<!--          <span v-if="scope.row.status==23">已过期</span>-->
        </template>
      </el-table-column>
      <el-table-column label="授权状态">
        <template slot-scope="scope">
          <span>{{getAuthStatus(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="" label="共享">
        <template slot-scope="scope">
          <span>{{ scope.row.recommend==0? '否' :'是'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="策略锁定">
        <template slot-scope="scope">
          <span>{{ scope.row.lockStr==1? '否' :'是'}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="tradeStrategy"
        label="交易策略"
        width='150'
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <div>{{scope.row.tradeStrategy}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="订阅费" width='100'>
        <template slot-scope="scope">
          <span>${{ scope.row.price}}/月</span>
        </template>
      </el-table-column>
      <el-table-column prop="subAmount" label="订阅限制金额">
        <template slot-scope="scope">
          <span>${{ scope.row.subAmount || 0 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="regdate" label="创建时间" width='150'/>

      <el-table-column
          label="操作"
          width='200'
          align="center">
        <template slot-scope="scope">
          <el-button
              type="primary"
              size="mini"
              class="my-button"
              v-if="checkPermission(['ADMIN','COMMUNITYMTACCOUNT_ALL','COMMUNITYMTACCOUNT_SELECT'])"
              @click="openMt4Info(scope.row.login,scope.row.serverId)"
          >查看</el-button>
          <el-dropdown
              size="mini"
          >
            <el-button size="mini" type="success">
              更多操作
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                  v-if="checkPermission(['ADMIN','COMMUNITYMTACCOUNT_ALL','COMMUNITYMTACCOUNT_SUBAMOUNT']) && scope.row.accType == 1"
                  class="dropdown-item"
              >
                <div
                    class="menu-item-inner"
                    @click="editHandle(scope.row)"
                >
                  编辑
                </div>
              </el-dropdown-item>
              <el-dropdown-item
                  v-if="scope.row.isMock !==1 && checkPermission(['ADMIN'])"
                  class="dropdown-item"
              >
                <div
                  class="menu-item-inner"
                  @click="positionHandle(scope.row)"
                >
                  持仓订单同步
                </div>
              </el-dropdown-item>
              <el-dropdown-item
                  class="dropdown-item"
                  v-if="scope.row.isMock !==1 && checkPermission(['ADMIN'])"
              >
                <div
                    class="menu-item-inner"
                    @click="historyHandle(scope.row)"
                >历史订单同步</div>
              </el-dropdown-item>
<!--              <el-dropdown-item
                  class="dropdown-item"
                  v-if="checkPermission(['ADMIN','COMMUNITYMTACCOUNT_ALL','COMMUNITYMTACCOUNT_SUBAMOUNT']) && scope.row.accType == 1"
              >
                <div
                    class="menu-item-inner"
                    @click="setHandle(scope.row.subAmount,scope.row.id)"
                >订阅限制</div>
              </el-dropdown-item>-->
              <el-dropdown-item
                  class="dropdown-item"
                  v-if="checkPermission(['ADMIN','COMMUNITYMTACCOUNT_ALL','COMMUNITYMTACCOUNT_LOCKSTR']) && scope.row.accType == 1"
              >
                <div
                    class="menu-item-inner"
                    @click="lockTradeStrategyHandle(scope.row)"
                >{{getLockStrategyText(scope.row)}}交易策略</div>
              </el-dropdown-item>
              <el-dropdown-item
                  class="dropdown-item"
                  v-if="checkPermission(['ADMIN','COMMUNITYMTACCOUNT_ALL','COMMUNITYMTACCOUNT_STATUS'])"
              >
                <div
                    class="menu-item-inner"
                    @click="soldOutHandle(scope.row.status,scope.row.id,scope.row.dismount)"
                >{{scope.row.dismount == 1 ? '上架' : '下架'}}</div>
              </el-dropdown-item>
              <el-dropdown-item
                  class="dropdown-item"
                  v-if="checkPermission(['ADMIN','COMMUNITYMTACCOUNT_ALL','COMMUNITYMTACCOUNT_ISRECOMMEND']) && scope.row.accType == 1"
              >
                <div
                    class="menu-item-inner"
                    @click="shareHandle(scope.row.recommend,scope.row.id)"
                >{{getShareText(scope.row.recommend)}}</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
    <sold-out-dialog
      :sold-out-show="soldOutShow"
      @close-outdialog="closeDialogHandle"
      @submit="submitHandle"
      :lowest="cacheLowest"
    ></sold-out-dialog>
    <lock-strategy-dialog
    :lock-dialog-show="lockStrategyDialogShow"
    :is-lock-strategy="isLockStrategy"
    :data-id="currentId"
    @close-dialog="lockStrategyDialogShow = false"
    ></lock-strategy-dialog>
    <edit-dialog
        :form-data="formData"
      :dialog-show="editDialogShow"
      @close-outdialog="closeEditDialog"
        @edit-success="editSuccess"
    ></edit-dialog>
  </div>
</template>

<script>
import editDialog from "@/components/community/customer/accmgr/editDialog";
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/community/customer/accmgr/header'
import soldOutDialog from '@/components/community/customer/accmgr/soldOutDialog'
import { edit,getPlatformInfo, syncOpeningOrder,syncClosedOrder} from '@/api/community/srCommunityMtaccount'
import lockStrategyDialog from '@/components/community/customer/cusmgr/lockStrategyDialog'
import { mapGetters } from 'vuex'
export default {
  name:'accmgr',
  components: {
    eHeader,
    soldOutDialog,
    lockStrategyDialog,
    editDialog,
  },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      sup_this: this,
      belongUserId:'',
      accType:'',
      soldOutShow: false,
      cacheLowest: 0,
      currentId: 0,
      lockStrategyDialogShow: false,
      isLockStrategy: false,
      sites: [],

      editDialogShow: false,
      formData:{
        mt4Id: '',
        lowest: '',
        setCopys: '',
        setCopysMoney: '',
      }
    }
  },
  beforeDestroy(){
    this.clearPartnerUserId();
  },
  computed:{
    ...mapGetters(['partnerUserId'])
  },
  mounted(){
    this.getPlatformInfo()
    this.accType = this.$route.query.accType;
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    editSuccess(){
      this.init();
      this.editDialogShow = false;
    },
    closeEditDialog(){
      this.editDialogShow = false;
    },
    editHandle(row){
      this.editDialogShow = true;
      this.formData.rowId = row.id;
      this.formData.lowest = row.subAmount;
      this.formData.setCopys = row.fakeCopyCount;
      this.formData.setCopysMoney = row.fakeCopyAmount;
    },
    clearPartnerUserId(){
      this.$store.commit('clearPartnerUserId');
    },
    getPlatformName(row){
      if(row.isMock !== 1){
        return row.platformName;
      }else {
        return '模拟';
      }
    },
    getStatus(row){
      if(row.dismount === 1){
        return '是';
      }else {
        return '否'
      }
    },
    getAuthStatus(row){
      if(row.siteType == 5){
        if(row.accType == 1){
          return '-';
        }else {
          if(row.authorization){
            return '已授权';
          }else {
            return '未授权';
          }
        }
      }else {
        return '-';
      }
    },
    beforeInit() {
      this.url = 'community/crm/srCommunityMtaccount'
      const sort = 'cm.id,desc'
      const query = this.query
      this.params = {
        page: this.page,
        size: this.size,
        sort: sort,
        userId: this.query.userId,
        siteId: this.query.siteId,
        userName: this.query.userName,
        accType: this.query.accType,
        login: this.query.login,
        phone: this.query.phone,
        isMock: this.query.isMock,
        isRecommend: this.query.isRecommend,
      }
      if(this.$refs.eheader.partnerId){
        this.params.partnerUserId = this.$refs.eheader.partnerId;
      }
      if(this.query.platformName){
        this.params.platformName = this.query.platformName;
      }
      if(this.query.shelves !== ''){
        this.params.dismount = this.query.shelves;
      }
      if(this.query.status){
        this.params.status = this.query.status;
      }
      if (this.$refs.eheader.$refs.searchcommunity.siteId) {
        this.params['siteId'] = this.$refs.eheader.$refs.searchcommunity.siteId
      }
      return true
    },
    getPlatformInfo() {
      const params = {page:0,size:200}
      getPlatformInfo(params).then(res => {
        let arrList = [];
          for (let i in res) {
            var j = {};
            j.platformName = res[i];
            j.id = i;
             this.sites.push(j);
          }
      });
    },
    openMt4Info(login,serverId) {
      this.$router.push({
        name: "commAccdetail",
        params: {
          login: login,
          serverId: serverId,
        }
      });
    },
    setHandle(subAmount,id){
      this.soldOutShow = true;
      this.currentId = id;
      if(subAmount !== null ){
        console.log(subAmount)
        this.cacheLowest = subAmount;
      }else {
        this.cacheLowest = 0;
      }
    },
    //// status: 11, 20已上架, 23, 已下架
    soldOutHandle(status,id,dismount){
      this.$confirm(`是否要${(dismount == 1) ? '上架': '下架'}`,'提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        //点击确定的操作(调用接口)
        edit({
          status: (dismount == 1) ? 11 : 23,
          id: id
        }).then((res) => {
          this.init();
          this.$message({
            type: 'success',
            message: '操作成功'
          })
        })
      }).catch(() => {
        //几点取消的提示
      });
    },
    shareHandle(shareStatus,id){
      this.$confirm(`是否要${(shareStatus == 0 ) ? '共享': '取消共享'}`,'提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        //点击确定的操作(调用接口)
        edit({
          isRecommend: shareStatus == 1 ? 0 : 1,
          id: id
        }).then((res) => {
          this.init();
          this.soldOutShow = false;
          this.$message({
            type: 'success',
            message: '操作成功'
          })
        })
      }).catch(() => {
        //几点取消的提示
      });
    },
    closeDialogHandle(){
      this.soldOutShow = false;
    },
    // status: 11, 20已上架, 23, 已下架
    // getSoldOutText(dismount){
    //   return ( dismount == 0 || dismount == 1 )? '下架': '上架' ;
    // },
    getShareText(recomend){
      return recomend == 0 ? '共享' : '取消共享';
    },
    submitHandle(lowest){
      edit({
        subAmount: lowest,
        id: this.currentId
      }).then((res) => {
        this.init();
        this.soldOutShow = false;
        this.$message({
          message: '操作成功'
        });
      })
    },
    // 锁定
    getLockStrategyText(rowData){
      return rowData.lockStr == 1 ? '锁定' : '解锁';
    },
    lockTradeStrategyHandle(rowData){
       this.lockStrategyDialogShow = true;
       this.currentId = rowData.id;
       this.isLockStrategy = rowData.lockStr == 1 ? true : false;
    },
    hasPartneridEmit(partnerUserId){
      this.init();
    },
    positionHandle(row){
      syncOpeningOrder({
        serverId: row.serverId,
        login: row.login
      }).then((res) => {
        this.$notify({
          title: '同步成功',
          type: 'success',
          duration: 1000
        })
      })
    },
    historyHandle(row){
      syncClosedOrder({
        serverId: row.serverId,
        login: row.login
      }).then((res) => {
        this.$notify({
          title: '同步成功',
          type: 'success',
          duration: 1000
        })
      });
    },
  }
}
</script>
<style lang="less">
  .trade-strategy {
    padding-right: 0;
  }
  .el-tooltip__popper {
    width: 500px;
  }
</style>
<style lang="less" scoped>
.my-button {
  margin-right: 5px;
}
.menu-item-inner {
  height: 40px;
  line-height: 40px;
}
</style>
