<template>
  <div class="head-container">
    <!-- 搜索 -->
    <search-community ref="searchcommunity"></search-community>
    <el-select class="filter-item" clearable v-model="query.platformName" filterable placeholder="选择平台名称" style="width: 180px;"  @change="sitesChange">
      <el-option
        v-for="item in sites"
        :key="item.id"
        :value="item.id"
        :label="item.platformName"
      >
      </el-option>
    </el-select>
    <el-input v-model="query.userId" clearable placeholder="输入客户ID" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-input v-model="query.userName" clearable placeholder="输入客户昵称" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-input v-model="query.login" clearable placeholder="输入MT4" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-input v-model="query.phone" clearable placeholder="输入客户电话" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-select v-model="query.accType" clearable placeholder="选择社区角色" class="filter-item" style="width: 130px" @change="toQuery">
      <el-option v-for="item in queryAccTypeOptions" :key="item.key" :label="item.display_name" :value="item.key"/>
    </el-select>
    <el-select v-model="query.isMock" clearable placeholder="选择账户类型" class="filter-item" style="width: 130px" @change="toQuery">
      <el-option v-for="item in queryMockOptions" :key="item.key" :label="item.display_name" :value="item.key"/>
    </el-select>
    <el-select
      v-model="query.shelves"
      clearable
      placeholder="是否下架"
      class="filter-item"
      style="width: 130px"
      @change="toQuery"
    >
      <el-option v-for="item in shelvesOption" :key="item.key" :label="item.display_name" :value="item.key"/>
    </el-select>
    <el-select
      v-model="query.status"
      clearable
      placeholder="账户状态"
      class="filter-item"
      style="width: 130px"
      @change="toQuery"
    >
      <el-option v-for="item in statusOption" :key="item.key" :label="item.display_name" :value="item.key"/>
    </el-select>
    <el-select v-model="query.isRecommend" clearable placeholder="是否共享" class="filter-item" style="width: 130px" @change="toQuery">
      <el-option v-for="item in queryRecommendOptions" :key="item.key" :label="item.display_name" :value="item.key"/>
    </el-select>
    <el-input
      v-model="partnerId"
      clearable
      class="filter-item"
      style="width: 150px"
      placeholder="请输入合作伙伴id"
      @keyup.enter.native="toQuery"
    ></el-input>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
  </div>
</template>

<script>
  import searchCommunity from '@/components/common/searchCommunity'
import checkPermission from '@/utils/permission' // 权限判断函数
import { mapGetters } from 'vuex'
export default {
    components:{
      searchCommunity,
    },
  props: {
    query: {
      type: Object,
      required: true
    },
    sites: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      queryTypeOptions: [
        { key: 'userId', display_name: '社区用户ID' },
        { key: 'login', display_name: 'mt4账号' },
        { key: 'email', display_name: '邮箱' },
        { key: 'phone', display_name: '手机号码' },
      ],
      queryAccTypeOptions: [
        { key: '1', display_name: '交易员' },
        { key: '2', display_name: '跟随者' },
      ],
      queryMockOptions: [
        { key: '1', display_name: '模拟' },
        { key: '2', display_name: '真实' },
      ],
      queryRecommendOptions: [
        { key: '0', display_name: '否' },
        { key: '1', display_name: '是' },
      ],
      shelvesOption: [
        { key: '0', display_name: '否' },
        { key: '1', display_name: '是' },
      ],
      statusOption: [
        { key: 10, display_name: '开户中' },
        { key: 20, display_name: '有效' },
        { key: 12, display_name: '开户失败' },
        // { key: 20, display_name: '绑定成功' },
        { key: 21, display_name: '绑定失败' },
        { key: 22, display_name: '失效' },
        // { key: 23, display_name: '已过期' },
      ],
      partnerId: ''
    }
  },
  watch:{
    partnerUserId(newValue,oldValue){
      if(newValue){
        this.partnerId = newValue;
        this.$parent.page = 0;
        this.$parent.init();
      }
    }
  },
  computed: {
    ...mapGetters(['partnerUserId']),
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$parent.page = 0;
      this.$parent.init();
    },
    dataFilter(val) {
      clearTimeout(this.timer);
      this.timer = setTimeout(function () {
        console.log(val);
      },1000)
    },
    sitesChange(val) {
      if(val) {
        let obj = {};
        obj = this.sites.find((item)=>{
            return item.id === val;
        });
        this.query.platformName = obj.platformName;
      }
    },
  }
}
</script>
