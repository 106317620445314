import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'community/crm/srCommunityMtaccount',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'community/crm/srCommunityMtaccount/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'community/crm/srCommunityMtaccount',
    method: 'put',
    data
  })
}

export function getPlatformInfo(params) {
  return request({
    url: 'community/crm/getDistinctSrPlatformInfo',
    method: 'get',
    params
  })
}


export function syncOpeningOrder(params) {
  return request({
    url: 'community/crm/syncOpeningOrder',
    method: 'get',
    params
  })
}

export function syncClosedOrder(params) {
  return request({
    url: 'community/crm/syncClosedOrder',
    method: 'get',
    params
  })
}

