<template>
    <el-dialog
      title="编辑"
      :visible="dialogShow"
      :append-to-body="true"
      width="500px"
      :before-close="beforeCloseHandle"
    >
      <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="120px"
          label-position="left"
          hide-required-asterisk
      >
        <el-form-item
            prop="lowest"
            label="订阅账号余额≥">
          <el-input
              v-model="form.lowest"
              placeholder="请输入"
          >
            <span
                class="prefix-el"
                slot="prefix"
            >$</span>
          </el-input>
        </el-form-item>
        <el-form-item
            prop="setCopys"
            label="虚拟订阅人数"
        >
          <el-input
              v-model="form.setCopys"
              placeholder="请输入"
          />
        </el-form-item>
        <el-form-item
            prop="setCopysMoney"
            label="虚拟订阅资金"
        >
          <el-input
              v-model="form.setCopysMoney"
              placeholder="请输入"
          >
            <span
                class="prefix-el"
                slot="prefix"
            >$</span>
          </el-input>
        </el-form-item>
        <el-form-item>
          <div class="footer-btn">
            <el-button type="text"
              @click="cancelHandle"
            >取消</el-button>
            <el-button type="primary"
              @click="sureHandle"
            >确认</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
</template>

<script>

  import { validatPositive } from '@/utils/validate'
  import { edit } from "@/api/community/srCommunityMtaccount";
  export default {
    name: 'editDialog',
    props:{
      dialogShow:{
        type: Boolean,
        default: false,
      },
      formData:{
        type: Object,
        default(){
          return {
            rowId: '',
            lowest: '',
            setCopys: '',
            setCopysMoney: '',
          }
        }
      }
    },
    watch:{
      formData:{
        handler(newVal,oldVal){
          this.form.lowest = newVal.lowest;
          this.form.setCopys = newVal.setCopys;
          this.form.setCopysMoney = newVal.setCopysMoney;
        },
        deep: true
      },
    },
    data() {
      var checkLowest = (rule, value, callback) => {
        setTimeout(() => {
          if (value && !validatPositive(value)) {
            callback(new Error('请输入正数'));
          } else {
            callback();
          }
        }, 500);
      };
      return {
        form: {
          lowest: '',
          setCopys: '',
          setCopysMoney: '',
        },
        rules:{
          lowest:[
            { validator: checkLowest, trigger: 'blur' }
          ],
          setCopys:[
            // { validator: checkLowest, trigger: 'blur' },
            /*{
              required: true,
              message: '请输入虚拟的订阅人数',
            }*/
          ],
          setCopysMoney:[
            { validator: checkLowest, trigger: 'blur' }
          ],
        }
      }
    },
    methods: {
      beforeCloseHandle() {
        this.$refs['form'].clearValidate();
        this.$emit('close-outdialog')
      },
      cancelHandle(){
        this.beforeCloseHandle();
      },
      sureHandle(){
        this.$refs['form'].validate((valid) => {
          if (valid) {
            // this.$emit('submit',this.formowe.lst);
            // ajax
            edit({
              subAmount: this.form.lowest || 0,
              id: this.formData.rowId,
              fakeCopyCount: this.form.setCopys || 0,
              fakeCopyAmount: this.form.setCopysMoney || 0,
            }).then((res) => {
              this.$emit('edit-success')
              this.$message({
                type: 'success',
                message: '编辑成功',
              });
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    }
  }
</script>

<style lang="less" scoped>
.footer-btn {
  margin-top: 20px;
  text-align: right;
}
.prefix-el {
  padding-left: 5px;
}
</style>
