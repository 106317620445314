<template>
    <el-dialog
      title="订阅限制"
      :visible="soldOutShow"
      :append-to-body="true"
      width="300px"
      :before-close="beforeCloseHandle"
    >
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item prop="lowest" label="订阅账号余额需不低于: ">
          <el-input v-model="form.lowest" style="width: 100px;"/>
        </el-form-item>
        <el-form-item>
          <div class="footer-btn">
            <el-button round type="primary"
              @click="cancelHandle"
            >取消</el-button>
            <el-button round type="success"
              @click="sureHandle"
            >确认</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
</template>

<script>
  import { validatPositive } from '@/utils/validate'
  export default {
    name: 'soldOutDialog',
    props:{
      soldOutShow:{
        type: Boolean,
        default: false,
      },
      lowest:{

      }
    },
    watch:{
      lowest(newVal){
        this.form.lowest = newVal;
      }
    },
    data() {
      var checkLowest = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('余额不能为空'));
        }
        setTimeout(() => {
          if (!validatPositive(value)) {
            callback(new Error('请输入正数'));
          } else {
            callback();
          }
        }, 500);
      };
      return {
        form: {
          lowest: this.lowest,
        },
        rules:{
          lowest:[
            { validator: checkLowest, trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      beforeCloseHandle() {
        this.$emit('close-outdialog')
      },
      cancelHandle(){
        this.beforeCloseHandle();
      },
      sureHandle(){
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.$emit('submit',this.form.lowest);
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    }
  }
</script>

<style scoped>
.footer-btn {
  margin-top: 20px;
  text-align: center;
}
</style>
