import { render, staticRenderFns } from "./soldOutDialog.vue?vue&type=template&id=996578a6&scoped=true"
import script from "./soldOutDialog.vue?vue&type=script&lang=js"
export * from "./soldOutDialog.vue?vue&type=script&lang=js"
import style0 from "./soldOutDialog.vue?vue&type=style&index=0&id=996578a6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "996578a6",
  null
  
)

export default component.exports